import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"retrieve-results"} />
		<Helmet>
			<title>
				IQ Test Results
			</title>
			<meta name={"description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:title"} content={"Brain Rank IQ Test"} />
			<meta property={"og:description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/proveit.webp?v=2024-03-04T16:16:07.691Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
		</Helmet>
		<Components.NavBar />
		<Section>
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 0px 0px" font="--headline1" color="--primary" as="h1">
					Retrieve your Results
				</Text>
			</Box>
			<Text margin="0px 0px 0px 0px" font="--lead" color="--primary">
				Enter your email address below and press the button to access your previous results. Whether you've taken the test once or multiple times, you'll be able to review the outcomes of all your sessions. For a deeper insight into your cognitive strengths and areas for improvement, we display the questions you answered incorrectly. Please note, questions that were skipped or not answered will not be included in this review. This feature is designed to help you track your progress over time and focus on enhancing your cognitive skills.
			</Text>
		</Section>
		<Section>
			<Box min-width="100px" min-height="100px">
				<Components.RetrieveResults />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<link href={"https://iqresearches.com/retrieve-results/"} rel={"canonical"} place={"endOfHead"} rawKey={"661e6433d35320ba9d328be8"} />
		</RawHtml>
	</Theme>;
});